/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

.lineMaxOne {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  /* number of lines to show */
  -webkit-line-clamp: 1;
  line-clamp: 1;
}

.noscroller::-webkit-scrollbar {
    display: none;
  }

.modal-class {
  --border-radius: 20px;
}

// ion-popover .popover-viewport {
//   display: contents;
// }

ion-modal.fullscreen {
  --width: 100%;
  --height: 100%;
  --border-radius: 0;
}

.close_button {
  color: #46667f;
  background-color: #dce3ec;
  transition: all 0.1s ease-in;
}

.close_button:hover {
  color: #ffffff;
  background-color: #46667f;
  transition: all 0.1s ease-in;
}

.fullpagemodal{
  .modal-wrapper {
   
      display: block;
      width: calc(100% - 250px);
      height: calc( 100% - 100px );
      max-height: none;

  }
}